/**
 * DataObject State class responsible for keeping track of properties like isLoaded, rowCount, etc.
 */
export default class DataObjectState {
    private _isLoading = false;
    private _isLoaded = false;
    private _isNextPageLoading = false;
    private _isRowCountLoading = false;
    private _isRowCountLoaded = false;
    private _isLoadingResolve?: () => void;
    private _rowCount: number | null = null;
    private _changedDate: Date | null = null;

    /** Promise that is created when a load starts and is resolved when it finishes. */
    loadingPromise?: Promise<void>;
    /** Indicates if the DataObject is currently loading. */
    get isLoading() { return this._isLoading; }
    set isLoading(pValue) {
        if (!this.loadingPromise && !this._isLoaded) {
            this.loadingPromise = new Promise(res => this._isLoadingResolve = res);
        }
        this._isLoading = pValue;
    }   
    /**
     * Indicates if the DatObject has been loaded at least once. 
     * New load() calls do not reset this property.
     */
    get isLoaded() { return this._isLoaded; }

    set isLoaded(pValue) {
        this._isLoaded = pValue;
        if (this._isLoadingResolve) { this._isLoadingResolve(); }
    }
    /** Indicates if the DataObject row count is currently loading. */
    get isRowCountLoading() { return this._isRowCountLoading; }
    set isRowCountLoading(pValue) {
        this._isRowCountLoading = pValue;
    }
    /**
     * Indicates if the DataObject row count has been loaded at least once. 
     * New loads will not reset this property.
     */
    get isRowCountLoaded() { return this._isRowCountLoaded; }
    set isRowCountLoaded(pValue) {
        this._isRowCountLoaded = pValue;
    }

    /**
     * Row count of this DataObject, will be null initially. 
     * Can be manually loaded through record source.
     */
    get rowCount() { return this._rowCount; }
    set rowCount(value) { this._rowCount = value; }

    /**
     * Indicates if the next page is being loaded. 
     * This is set by DynamicLoading.
     */
    get isNextPageLoading() { return this._isNextPageLoading; }
    set isNextPageLoading(value) { this._isNextPageLoading = value; }

    /**
     * Set date after insert, update and delete operations
     */
    get changedDate() { return this._changedDate; }
    set changedDate(value) { this._changedDate = value; }
} 